/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.jumbotron {
    background: url("./images/homepage.jpg") no-repeat center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

    @media (min-width: 768px) {
        .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
    /* z-index: 10; */
    height: 100%;
    padding-left: 16px;
}

.dropdown-btn {
    border: none;
    color: #002E5D;
    background-color: white;
    /*font-family: 'HCo Ringside Narrow SSm', Arial, sans-serif;*/
    font-size: 16px;
    height: 100%;
    line-height: 1.6;
    box-shadow: none;
}

.dropdown-btn:hover {
    background-color: #e6e6e6;
    border: 0px !important;
}

.dropdown-btn:focus {
    border: none;
    box-shadow:none;
}

.dropdown-child {
    display: none;
    min-width: 200px;
    position: absolute;
    left: 0px;
    background-color: white;
    box-shadow: 0 4px 8px #c5c5c5;
}

.dropdown-child a {
    color: #002e5d;
    font-size: 16px;
    padding: 12px;
    text-decoration: none;
    display: block;
    /*width: 91%;*/
    min-width: 200px;
}

.dropdown-child a:hover {
    background-color: #e6e6e6;
}

.dropdown-item {
    display: block;
    /*width: 100%;*/
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown:hover .dropdown-child {
    display: block;
    background-color: white;
}

.navbar-body {
    line-height: normal !important;
    font-family: 'HCo Ringside Narrow SSm', Arial, sans-serif;
    font-size: 16px;
}

byu-header:not(.byu-component-rendered) byu-menu a {
    font-size: 14px !important;
    padding: 8px 22px !important;
}