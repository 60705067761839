/*- Typograpy and resets -*/
.byu-alert {
    color: #002e5d !important;
    background-color: #cee3f5 !important;
    border-color: #cee3f5 !important;
}
@font-face {
    font-family: hebrewFont;
    src: url('font/WCrFrank.ttf');
}
@import url(https://fonts.googleapis.com/earlyaccess/notosanshebrew.css);

.iconPlay {
    color: #0062B8;
    cursor: pointer;
}
.iconPlay:hover {
    color: #22bb04;
    cursor:     pointer;
}
@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}
.iconPlayPlaying {
    color: #22bb04;
    cursor: pointer;
    animation: blinker 2s linear infinite;
}

.rightarrowcard {
    position: relative;   
    float:left;
}
.rightarrowcard:hover {
    color: #0062B8;
    background: #fff;
    cursor:pointer;
}

.leftarrowcard {
    position: relative;
    float: right;
    cursor: pointer;
}
.leftarrowcard:hover {
    color: #0062B8;
    background: #fff;
}

.explanationtext {
    position: float;
}

.fixedContainter {
    position:relative;
    width: 100%;
    height:10%;
    bottom: 0px;
}

.cardButtonLeft {
    position:relative;
    bottom:15px;
    left:20px;
    z-index:2;
}

.cardButtonRight {
    position:relative;
    bottom:15px;
    right:20px;
    text-align:right;
    z-index:2;
}

.cardCount {
    position:relative;
    width:100%;
    bottom:0px;
    font-weight: bold;
    text-align: center;
}

.showExplanationIcon {
    pointer-events:all;
}

.showExplanationIcon:hover {
    background-color: red;
}

.flashcard {
    width: 760px;
}

.termtext {
    font-size: 48px;
    position: relative;
    text-align: center;
    margin: 50px 0 10px 0;
    font-family: hebrewFont;
    height: 300px;
}

.termexplanation {
    font-size: 24px;
    position: absolute;
    text-align: center;
    top: 240px;
    width: 98%;
    padding-right: 30px;
}

.byu-color {
    background-color: #002e5d !important;
    color:white !important;
}

.byu-color:hover{
    text-decoration: underline #004790  !important;
    background-color: #004790 !important;
}

.termexplanationsmall {
    font-size: 20px;
    position: absolute;
    text-align: center;
    top: 240px;
    width: 98%;
    padding-right: 30px;
}

.modelpronunciation {
    position: relative;
    text-align: center;
}

.termdefinition {
    font-size: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
    height: 300px;
}

.spin-icon {    
    position: absolute;
    right: 0;
    z-index:1;
    margin-top: 10px;
    margin-right:10px;
    color: gray;
}

/*- Card container -*/
.card-container {
    position: relative;
    z-index: 1;
    margin: 32px auto;
    width: 80vw;
    max-width: 790px;
    height: 45vw;
    max-height: 450px;
    perspective: 1000px;
}
.card-container-no-Flip {
    position: relative;
    z-index: 1;
    margin: 32px auto;
    width:100%;
    width: 790px;
    height: 450px;
    perspective: 1000px;
}
@media only screen and (max-width : 1050px) and (min-width : 800px) {
    /* Styles */
    .scaled {
    transform-origin: top left;
    transform: scale(0.71);
    }
    .cardSection {
        height: 365px;
        width: 698px;
    }
}

@media only screen and (max-width : 799px) and (min-width : 500px) {
    /* Styles */
    .scaled {
        transform-origin: top left;
        transform: scale(0.47);
    }
    .cardSection {
        height: 242px;
        width: 461px;
    }
}

@media only screen and (min-width : 400px) and (max-width : 500px) {
    /* Styles */
    .scaled {
        transform-origin: top left;
        transform: scale(0.38);
    }

    .cardSection {
        height: 196px;
        width: 374px;
    }
}

@media only screen and (max-width :400px) {
    /* Styles */
    .scaled {
        transform-origin: top left;
        transform: scale(0.3);
    }
    .cardSection {
        height: 152px;
        width: 295px;
    }
}

.leftArrow{
    max-width:50px;
    width: 1vw;
}
.rightArrow {
    max-width: 50px;
    width: 1vw;
}
img {
    max-width: 100%;
    max-height: 100%;
}

/*- Card body -*/
.card-body {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all .5s linear;
    padding:0!important;
}

/*- Flipping the card -*/
.card-container:active .card-body {
    transform: rotateY(180deg);
    z-index: 1;
    transition-delay:.05s;
}

.card-container:active > .card-body > .side-front {
    opacity: 0;
    visibility: hidden;
    transition: opacity 4.05s ease-in, visibility .6s linear;
}

/*- Card sides -*/
.card-side {
    position: absolute;
    top: 0;
    //overflow: hidden;
    width: 100%;
    height: 100%;
    color: #212121;
    background-color: #fff;
    backface-visibility: hidden;
    box-shadow: 0 10px 35px rgba(50,50,93,.1),0 2px 15px rgba(0,0,0,.07);
}

/*- Front side -*/
.side-front [class^=col-xs]:first-of-type {
    padding-left: 0;
}

.side-front-content {
    padding-top: 32px;
    /*padding-right: 32px;*/
    padding-bottom: 32px;
}

/*- Back side -*/
.side-back {
    z-index: 2;
    padding: 32px;
    text-align: center;
    transform: rotateY(180deg);
}

/*- Form -*/
.card-form {
    margin-top: 32px;
}

.card-form .row + .row,
.card-form .row + fieldset,
.card-form fieldset + fieldset {
    margin-top: 16px;
}

input,
textarea {
    padding: 8px;
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #eee;
    border-left: 0;

    &:focus {
        outline: 0;
        border-bottom: 1px solid #0c81f6;
    }
}

textarea {
    max-height: 80px;
    resize: vertical;
}

.biblicalReference{
    position: relative;
    bottom:0;
    margin-top: 10px;
    margin-left:20px;
}

.cardNumber{
    bottom:0;    
    padding-bottom: 32px;
    padding-right: 100px;
    padding-left: 100px;
}

.btn-primary {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #0c81f6;
    border: none;
    box-shadow: 0 10px 35px rgba(50,50,93,.1),0 2px 15px rgba(0,0,0,.07);
    transition: background-color .25s ease-in, box-shadow .25s ease-in;

    &:focus,
    &:hover {
        background-color: lighten(#0c81f6, 15%);
        box-shadow: 0 18px 35px rgba(50,50,93,.1),0 8px 15px rgba(0,0,0,.07);
    }
}

/*- Social links -*/
.social-links {
    padding: 0;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    list-style-type: none;

    li:nth-of-type(n+2) {
        margin-left: 24px;
    }

    a {
        color: #212121;
        transition: opacity .25s ease-in;

        &:focus,
        &:hover {
            opacity: .5;
        }
    }

    i {
        font-size: 24px;
    }
}
